.spinner-loader {
  animation: spin 1s infinite linear;
  border: solid 2px transparent;
  border-radius: 50%;
  border-right-color: #00B28E;
  border-top-color: #00B28E;
  box-sizing: border-box;
  height: 12px;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 12px;
  z-index: 1;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

