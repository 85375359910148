.loader {
    animation: spin 1s infinite linear;
    border: solid 2px transparent;
    border-radius: 50%;
    border-right-color: black;
    border-top-color: black;
    box-sizing: border-box;
    height: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    width: 20px;
    z-index: 1;
  
    &:after {
      animation: spin 3s infinite linear;
      border: solid 2px transparent;
      border-radius: 50%;
      border-right-color: #EEF3F5;
      border-top-color: #EEF3F5;
      box-sizing: border-box;
      content: "";
      height: 12px;
      left: 2px;
      position: absolute;
      top: 2px;
      width: 12px;
    }
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  