.calculator-wrapper {
  position: absolute;
  background: white;
  z-index: 1;
  height: 430px;
}

.calculator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 0 #aaa;
  .calculator-keypad {
    height: 350px;
    display: flex;
  }
  .input-keys {
    width: 240px;
  }
  .function-keys {
    display: flex;
    background: linear-gradient(
      to bottom,
      rgba(202, 202, 204, 1) 0%,
      rgba(196, 194, 204, 1) 100%
    );
    .calculator-key {
      font-size: 2em;
    }
    .key-multiply {
      line-height: 50px;
    }
  }
  .digit-keys {
    background: #e0e0e7;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .calculator-key {
      font-size: 2.25em;
    }
    .key-0 {
      width: 160px;
      text-align: left;
      padding-left: 32px;
    }
    .key-dot {
      padding-top: 1em;
      font-size: 0.75em;
    }
  }
  .operator-keys {
    background: linear-gradient(88.88deg, #000000 0%, #3d3d3d 100%);
    .calculator-key {
      color: white;
      border-right: 0;
      font-size: 3em;
    }
  }
  .operator-income-keys {
    background: linear-gradient(88.88deg, #7fdef5 0%, #7ef5ad 100%);
    .calculator-key {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .operator-consumption-keys {
    background: linear-gradient(88.88deg, #ff9d85 0%, #ffd585 100%);
    .calculator-key {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
