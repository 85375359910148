:root {
  --animation-duration: 1.5;
  --iteration-count: 6;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.icon-animated {
  display: inline-block;
  background-color: rgb(226, 253, 237);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  animation: pulse var(--animation-duration) ease-in-out var(--iteration-count);
}
