.calculator-display {
  color: white;
  background: #8d9193;
  line-height: 80px;
  font-size: 3em;
  position: relative;
  flex: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &__auto-scaling {
    display: inline-block;
    padding: 0 30px;
    position: absolute;
    right: 0;
    transform-origin: right;
  }
}
