@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.eot');
  src: url('../fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.eot');
  src: url('../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.eot');
  src: url('../fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.eot');
  src: url('../fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.eot');
  src: url('../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraLight.eot');
  src: url('../fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Thin.eot');
  src: url('../fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.eot');
  src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.eot');
  src: url('../fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

