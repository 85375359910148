.react-calendar {
  border: 1px solid silver;
  width: 100%;
  z-index: 9999;
  max-width: 315px;
  min-width: 265px;
  position: absolute;
  border-radius: 10px;
  height: 100%;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  background: none;
}

.react-calendar__year-view__months {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #00B28E;

  abbr {
    text-decoration: none;
  }
}

abbr {
  text-transform: capitalize;
}

.react-calendar__navigation button[disabled] {
  background-color: rgba(238, 243, 245, 0.5);
  cursor: default;
  visibility: hidden;
}

.react-calendar__century-view__decades,
.react-calendar__decade-view__years,
.react-calendar__year-view__months,
.react-calendar__month-view {
  padding: 16px;
}

.react-calendar__tile.react-calendar__century-view__decades__decade,
.react-calendar__tile.react-calendar__decade-view__years__year,
.react-calendar__tile.react-calendar__year-view__months__month {
  height: 56px !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: unset;
}

.react-calendar__navigation {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: unset;
}

.react-calendar__navigation button {
  min-width: unset !important;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: rgba(238, 243, 245, 0.5) !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  width: 40px;
  height: 40px;
}

.react-calendar__tile.react-calendar__month-view__days__day:hover {
  border-radius: 50%;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  min-width: unset !important;
}

.react-calendar__navigation {
  background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%) !important;
  height: 56px;
  display: flex;
  align-items: center;
}

.react-calendar__navigation__label:hover {
  background-color: rgba(238, 243, 245, 0.5) !important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background-color: #EDF4F7 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__days__day {
  background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.react-calendar__tile.react-calendar__year-view__months__month:hover,
.react-calendar__tile.react-calendar__century-view__decades__decade:hover,
.react-calendar__tile.react-calendar__decade-view__years__year:hover {
  background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%), #FFFFFF;
  border-radius: 12px;
}

.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__next2-button:hover,
.react-calendar__navigation__prev2-button:hover,
.react-calendar__navigation__prev-button:hover {
  background-color: rgba(238, 243, 245, 0.5) !important;
  cursor: pointer;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  color: black !important;
  background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%);
}

.react-calendar__month-view__days__day {
  color: black;
  font-size: 13px;
}

.react-calendar__navigation__arrow .react-calendar__navigation__next-button {
  height: 14px;
}
