html,
body {
  background-size: cover;
  background-position: center;
  height: 100%;
  font-family: Inter, sans-serif;
  margin: 0;
  padding: 0;
}

/*body > div {*/
/*  height: 100%;*/
/*}*/

#ed-class-translator-listener {
  height: unset;
}

a {
  text-decoration: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

@page :left {
  margin: 1cm 2cm 1cm 1.5cm;
}
@page :right {
  margin: 1cm 2cm 1cm 1.5cm;
}

.__react_component_tooltip {
  pointer-events: auto !important;
}

::-webkit-scrollbar {
  width: 0;
}

.MuiDialog-root::-webkit-scrollbar {
  width: 12px;
}

.MuiDialog-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiDialog-root::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
}

.scrollable-list::-webkit-scrollbar,
.scrollable-block::-webkit-scrollbar,
.MuiTableContainer-root::-webkit-scrollbar,
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.scrollable-block::-webkit-scrollbar-track-piece:end,
.MuiAutocomplete-listbox::-webkit-scrollbar-track-piece:end {
  margin-bottom: 16px;
}

.scrollable-list::-webkit-scrollbar-thumb,
.scrollable-block::-webkit-scrollbar-thumb,
.MuiTableContainer-root::-webkit-scrollbar-thumb,
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: rgba(24, 29, 31, 0.15);
  border-radius: 8px;
}

.ReactVirtualized__List {
  overflow: scroll !important;
}

.ReactVirtualized__List:last-child {
  /*padding-bottom: 50px;*/
}

.ReactVirtualized__List::-webkit-scrollbar {
  width: 6px;
}

.ReactVirtualized__List::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
}

.ReactVirtualized__List::-webkit-scrollbar-thumb {
  background: rgba(24, 29, 31, 0.16);
  border-radius: 8px;
}

@media all and (max-width: 1280px) {
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-right: 18px;
  }
}

@media all and (max-width: 1024px) {
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-right: 18px;
  }
}

@media all and (max-width: 768px) {
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-right: 12px;
  }
}

::placeholder {
  text-overflow: ellipsis;
}

.Mui-disabled {
  opacity: 0.3;
}

:focus {
  outline: none;
}

.Toastify__toast--default {
  border-radius: 12px;
  width: 330px;
  background: #ffffff;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.16);
  min-height: 48px;
}

.Toastify__toast-body {
  font-size: 13px;
  line-height: 16px;
  color: black;
  padding-left: 16px;
}

.Toastify__progress-bar--default {
  background: linear-gradient(
    271.83deg,
    rgba(0, 0, 0, 0.05) 4.54%,
    rgba(0, 0, 0, 0.05) 33.01%,
    rgba(0, 0, 0, 0.08) 44.39%,
    rgba(0, 0, 0, 0.05) 56.86%,
    rgba(0, 0, 0, 0.05) 81.83%
  );
}

@media all and (max-width: 380px) {
  .MuiFormLabel-root {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 225px;
    overflow: hidden;
  }
}

.MuiInputBase-root.Mui-disabled {
  color: inherit !important;
  opacity: 0.4;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
    display: flex;
    justify-content: center;
  }
}
