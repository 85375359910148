.progress-value {
  animation: load 5s linear;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);
  flex: 1;
}

@keyframes load {
  0% { width: 0; }
  100% { width: 70%; }
}
