@import 'antdt.css';

.ant-tree {
  .ant-tree-treenode {
    display: flex;
    align-items: center;
    width: 100%;

    .ant-tree-switcher {
      align-self: auto;
    }

    .ant-tree-node-content-wrapper {
      display: flex;
      flex: 1;
      width: 100%;

      .ant-tree-title {
        flex: 1;
        width: 100%;
      }
    }
  }
}
