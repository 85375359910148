.calculator-key {
  display: block;
  width: 80px;
  height: 70px;
  border: none;
  border-top: 1px solid #777;
  border-right: 1px solid #666;
  background: none;
  text-align: center;
  line-height: 70px;
  padding: 0;
  font-family: inherit;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:active {
    box-shadow: inset 0 0 80px 0 rgba(0, 0, 0, 0.25);
  }
}
